import { graphql, useStaticQuery } from "gatsby"

const usePopupData = () => {
    const data = useStaticQuery(graphql`
      {
        success: contentfulPopup(contentId: { eq: "success" }) {
          headerText
          popupImage {
            title
            file {
              url
            }
          }
          bodyText
          websiteUrl
          clipboardImage {
            title
            file {
              url
            }
          }
          emailSubject
          emailBody {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          emailImage {
            title
            file {
              url
            }
          }
          facebookQuote {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          facebookPrimaryHashtag
          facebookImage {
            title
            file {
              url
            }
          }
        }
        failure: contentfulPopup(contentId: { eq: "failure" }) {
          headerText
          popupImage {
            title
            file {
              url
            }
          }
          emailBody {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `)

  const { success, failure } = data

    return { success, failure };
};

export default usePopupData;