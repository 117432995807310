import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { SectionContainer } from "../global"
import { ReactComponent as Logo } from "../../images/svgs/header/logo.svg"
import CtaFormMail from "../common/call_to_action/cta"

const Header = () => {

  const query = useStaticQuery(graphql`
    {
      hero: contentfulPageHero(contentId: { eq: "header" }) {
        headerText
        appPreview {
          title
          description
          file{
            url
          }
        }
        secondaryText
        ctaText
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <SectionContainer>
        <LogoWrapper>
          <Logo />
          <br />
        </LogoWrapper>
        <Flex>
          <ImageWrapper>
            <StyledImage
              src={query.hero.appPreview.file.url}
              alt={query.hero.appPreview.title}
            />
            <br />
          </ImageWrapper>
          <HeaderTextGroup>
            <h1>{query.hero.headerText}</h1>
            <h2>{query.hero.secondaryText}</h2>
            <StyledCtaFormMail location="header"/>
            <CtaText>{query.hero.ctaText}</CtaText>
          </HeaderTextGroup>
        </Flex>
      </SectionContainer>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  padding: 140px 0 160px 0;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(
    ${props => props.theme.color.blue100},
    transparent
  );
  background-color: ${props => props.theme.color.secondary.green};
  background-size: cover;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 140px 0 140px 0;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 120px 0 120px 0;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;
  display: grid;
  align-content: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: -20px;
  }

  h1 {
    margin: 0;
    @media (max-width: ${props => props.theme.screen.md}) {
      font-size: 42px;
      line-height: 52px;
    }
    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 36px;
      line-height: 49px;
    }
  }

  h2 {
    margin-bottom: 48px;
    font-weight: ${props => props.theme.fontWeight.normal};
    line-height: 33px;
    @media (max-width: ${props => props.theme.screen.md}) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 42px;
    }
    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  p {
    margin-bottom: 48px;
    color: ${props => props.theme.color.white};
  }
`

const CtaText = styled.p`
  color: ${props => props.theme.color.white};
  font-size: 18px;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 16px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 14px;
  }
`

const Flex = styled.div`
  display: grid;
  padding-top: 32px;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.xlg}) {
    padding-top: 32px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding-top: 32px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    padding-top: 65px;
  }
`
const LogoWrapper = styled.div`
  text-align: center;
  margin-top: -7%;
  margin-bottom: 4%;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: -20%;
    margin-bottom: 3%;
  }
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.img`
  margin-left: 40px;
  width: 55%;
  @media (max-width: ${props => props.theme.screen.xlg}) {
    width: 50%;
  }
  @media (max-width: ${props => props.theme.screen.lg}) {
    width: 70%;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 40%;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 30%;
    display: none;
  }
`
const StyledCtaFormMail = styled(CtaFormMail)`
  @media (max-width: ${props => props.theme.screen.sm}) {
    position: absolute;
    bottom: 40px;
    width: 85%;
  }
`
