import React from "react"
import styled from "styled-components"

import { SectionContainer, Section } from "../../global"

class LoadingPopup extends React.Component {

  render() {
    return (
      <StyledFailurePopupSection>
        <SectionContainer>
          <HeaderTextWrapper>
            <HeaderText>Sending Email..</HeaderText>
          </HeaderTextWrapper>
        </SectionContainer>
      </StyledFailurePopupSection>
    )
  }
}
export default LoadingPopup

const StyledFailurePopupSection = styled(Section)`
  background-color: ${props => props.theme.color.background.white};
  border: solid 3px ${props => props.theme.color.black};
  border-radius: 20px;
`

const HeaderText = styled.h3`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 0px;
  margin-bottom: 64px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 20px;
  }
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    line-height: 65px;
    @media (max-width: ${props => props.theme.screen.md}) {
      line-height: 45px;
    }
  }
`
