import React from "react"
import styled from "styled-components"
import CtaFormMail from "../common/call_to_action/cta"
import useCtaOnlyData from "../common/queries/cta_only_section"

import { SectionContainer, Section } from "../global"

const CallToActionOnlySection = () => {
  const {
    ctaonly: { headerText },
  } = useCtaOnlyData()

  return (
    <StyledSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <h3>{headerText}</h3>
        </HeaderTextWrapper>
        <CtaFormMail location="solo_cta"/>
      </SectionContainer>
    </StyledSection>
  )
}

export default CallToActionOnlySection

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.white.regular};
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    font-weight: ${props => props.theme.fontWeight.semibold};
    line-height: 65px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 64px;
  }
`
