import React from "react"
import styled from "styled-components"
import { SocialIcon } from "react-social-icons"
import useSocialIconData from "../common/queries/social_icons"
import { Link } from "gatsby"

import { SectionContainer, Section } from "../global"

const Footer = () => {

  function sendAnalyticsEvent(share_button) {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "footer_"+share_button, {
        send_to: process.env.GOOGLE_MEASUREMENT_ID,
      })
    }
  }

  const {
    icons: {
      iconsContent,
    },
  } = useSocialIconData()

  return (
    <StyledSection>
      <StyledContainer>
        <Grid>
          <SocialIconsWrapper>
            {iconsContent.map(data => (
              <button onClick={() => sendAnalyticsEvent(data.name)}>
                <SocialIcon
                  key={data.position}
                  network={data.name}
                  url={data.url}
                  bgColor="transparent"
                  fgColor="white"
                  style={{ height: 70, width: 70, margin: "-10px 0" }}
                  target="_blank"
                />
              </button>
            ))}
          </SocialIconsWrapper>
          <LinksWrapper>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-services">Terms and services</Link>
          </LinksWrapper>
        </Grid>
      </StyledContainer>
    </StyledSection>
  )
}

export default Footer

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.secondary.textGray};
  min-height: 96px;
  padding: 0px;
`
const StyledContainer = styled(SectionContainer)`
  padding: 16px 0 16px 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-bottom: 0px;
  }
`

const LinksWrapper = styled.div`
  margin: 0;
  display: grid;
  gap: 32px;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: auto auto;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 10px;
    margin: 32px 0;
  }

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  a {
    ${props => props.theme.fontSize.xsmall};
    font-weight: ${props => props.theme.fontWeight.semibold};
    color: ${props => props.theme.color.white};
    line-height: 22px;
    text-decoration: none;
    @media (max-width: ${props => props.theme.screen.md}) {
    }
  }
`
const Grid = styled.div`
  display: grid;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: auto auto;
  grid-auto-rows: 1fr;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
`

const SocialIconsWrapper = styled.div`
  display: grid;
  gap: 0px;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: auto auto auto;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
    text-align: center;
  }
`