import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { SectionContainer, Section } from "../global"

const Vision = () => {
  const query = useStaticQuery(graphql`
    {
      vision: contentfulSection(contentId: { eq: "vision" }) {
        headerText
        paragraphOne {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        paragraphTwo {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        sectionImage {
          title
          description
          file {
            url
          }
        }
      }
    }
  `)

  const {
    vision
  } = query

  return (
    <StyledVisionSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <HeaderText>{vision.headerText}</HeaderText>
        </HeaderTextWrapper>
        <StyledThinking src={vision.sectionImage.file.url} alt={vision.sectionImage.title} />
        <TextGroup>
          <p>{vision.paragraphOne.childMarkdownRemark.rawMarkdownBody} </p>
          <br />
          <p>{vision.paragraphTwo.childMarkdownRemark.rawMarkdownBody} </p>
        </TextGroup>
      </SectionContainer>
    </StyledVisionSection>
  )
}

export default Vision

const StyledVisionSection = styled(Section)`
  background-color: ${props => props.theme.color.background.grey};
`

const StyledThinking = styled.img`
  margin-bottom: 32px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`
const HeaderText = styled.h3`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 0px;
  margin-bottom: 64px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-weight: ${props => props.theme.fontWeight.semibold};
    ${props => props.theme.fontSize.large};
  }
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    line-height: 54px;
  }
`

const TextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  p {
    margin-top: 0;
    text-align: center;
    @media (max-width: ${props => props.theme.screen.sm}) {
      text-align: left;
    }
  }
`
