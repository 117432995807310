import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"
import Header from "../components/sections/header"
import Stakes from "../components/sections/stakes"
import Empathy from "../components/sections/empathy"
import Solutions from "../components/sections/solution"
import Advantage from "../components/sections/advantage"
import Vision from "../components/sections/vision"
import CallToActionOnlySection from "../components/sections/cta_only_section"
import HowItWorks from "../components/sections/howitworks"
import Demo from "../components/sections/demo"
import Team from "../components/sections/team"
import Footer from "../components/sections/footer"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const title = "Booking Bucket, a mobile app that makes cleaners more cash"
const metaDescription = "Want to grow your house cleaning business? Get our mobile app and start sending profitable quotes to your customers from your smartphone. No setup required."

const meta = [{
          name: `description`,
          content: metaDescription,
        }]

const HomePage = () => (
  <Layout>
    <Seo title={title} meta={meta} />
    <Header />
    <Stakes />
    <Empathy />
    <Solutions />
    <CallToActionOnlySection />
    <Vision />
    <HowItWorks />
    <Advantage />
    <Demo />
    <Team />
    <Footer />
  </Layout>
)

export default HomePage
