import React from "react"
import styled from "styled-components"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FacebookShareButton, EmailShareButton } from "react-share"
import { SectionContainer, Section } from "../../global"
import usePopupData from "../queries/popup"
import { useState } from "react"

const SuccessPopup = (data) => {
  const [copied, setCopied] = useState(false);

  function sendAnalyticsEvent(shareType) {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", shareType, {
        send_to: process.env.GOOGLE_MEASUREMENT_ID,
        email: data.email,
      })
    }
  }

  const {
    success: {
      headerText,
      popupImage: {
        title : popupTitle,
        file: { url : popupUrl },
      },
      bodyText,
      websiteUrl,
      clipboardImage: {
        title: clipTitle,
        file: { url : clipUrl },
      },
      emailSubject,
      emailBody: {
        childMarkdownRemark: {
        rawMarkdownBody : emailBody
      }
      },
      emailImage: {
        emailTitle,
        file: { url : emailUrl },
      },
      facebookQuote: {
        childMarkdownRemark: { rawMarkdownBody : fbrawMarkdownBody },
      },
      facebookPrimaryHashtag,
      facebookImage: {
        title : fbTitle,
        file: { url : fbUrl },
      },
    },
  } = usePopupData()

  return (
    <StyledSuccessPopupSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <HeaderText>{headerText}</HeaderText>
        </HeaderTextWrapper>
        <StyledImage src={popupUrl} alt={popupTitle} />
        <TextGroup>
          <p>{bodyText}</p>
        </TextGroup>
        <LinksGrid>
          <StyledCopyLink>
            <CopyToClipboard text={websiteUrl} onCopy={() => setCopied(true)}>
              <button onClick={() => sendAnalyticsEvent("clipboard")}>
                <img src={clipUrl} alt={clipTitle} />
                {copied ? <p>Url copied to clipboard</p> : null}
              </button>
            </CopyToClipboard>
          </StyledCopyLink>
          <EmailShareButton
            url={websiteUrl}
            subject={emailSubject}
            body={emailBody}
            onClick={() => sendAnalyticsEvent("email_share")}
          >
            <img src={emailUrl} alt={emailTitle} />
          </EmailShareButton>
          <FacebookShareButton
            url={websiteUrl}
            quote={fbrawMarkdownBody}
            hashtag={facebookPrimaryHashtag}
            onClick={() => sendAnalyticsEvent("fb_share")}
          >
            <img src={fbUrl} alt={fbTitle} />
          </FacebookShareButton>
        </LinksGrid>
      </SectionContainer>
    </StyledSuccessPopupSection>
  )
}

export default SuccessPopup

const StyledSuccessPopupSection = styled(Section)`
  background-color: ${props => props.theme.color.background.white};
  border: solid 3px ${props => props.theme.color.black};
  border-radius: 20px;
`

const StyledImage = styled.img`
  margin-bottom: 32px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
    margin-bottom: 0px;
  }
`
const HeaderText = styled.h3`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 0px;
  margin-bottom: 64px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 20px;
  }
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    line-height: 65px;
    @media (max-width: ${props => props.theme.screen.md}) {
      line-height: 45px;
    }
  }
`

const TextGroup = styled.div`
  margin: 0;
  margin-bottom: 32px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    @media (max-width: ${props => props.theme.screen.md}) {
      font-size: 18px;
      line-height: 32px;
    }
  }
`

const LinksGrid = styled.div`
  display: grid;
  justify-content: space-between;
  gap: 64px;
  align-content: center;
  grid-template-columns: auto auto auto;
  grid-auto-rows: 1fr;
  @media (max-width: ${props => props.theme.screen.sm}) {
    gap: 5px;
  }
`

const StyledCopyLink = styled.div`
  display: grid;
  grid-template-rows: auto auto;

  p {
    padding: 0;
    margin: 0;
    ${props => props.theme.fontSize.xxxsmall};
    color: ${props => props.theme.color.blue80};
    font-style: italic;

  }
`
