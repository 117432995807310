import React from "react"
import styled from "styled-components"
import { CtaForm, CtaInput, CtaButton } from "../../global"
import Modal from "react-modal"
import SuccessModal from "../call_to_action/success"
import FailureModal from "../call_to_action/failure"
import LoadingModal from "../call_to_action/loading_popup"
import { ReactComponent as BackImage } from "../../../images/svgs/privacy_terms/Back.svg"
import validate from "../call_to_action/email_validation"

const endpoints = {
  contact: "/.netlify/functions/sendEmail",
}

const axios = require("axios")

Modal.setAppElement(`#___gatsby`)
class CallToAction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      isModalOpen: false,
      error: false,
      validateError: "",
      submitted: false,
    }
  }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    const statesToUpdate = {}
    statesToUpdate[name] = value
    this.setState(statesToUpdate)
  }

  handleSubmit = e => {
    let { email, error } = this.state
    let data = { email, error }
    let validateError = validate(this.state)
    console.log("Validation Error" + validateError.email)
    if (validateError.email === "Valid") {
      this.setState({ validateError: validateError.email })
      axios
        .post(endpoints.contact, JSON.stringify(data))
        .then(response => {
          this.handleSuccess({response, email})
        })
        .catch(err => {
          this.handleError({ err, email })
        })
    } else {
      this.setState({
        validateError: validateError.email,
        email: "",
        submitted: false,
      })
    }
    e.preventDefault()
  }

  handleSuccess = data => {
    this.setState({
      email: "",
      error: false,
      submitted: true,
    })
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "sign_up_success_"+this.props.location, {
        send_to: process.env.GOOGLE_MEASUREMENT_ID,
        email: data.email
      })
    }
  }
  handleError = data => {
    this.setState({
      error: true,
      msg: data.err,
      submitted: true,
    })
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "sign_up_fail_"+this.props.location, {
        send_to: process.env.GOOGLE_MEASUREMENT_ID,
        email: data.email,
      })
    }
  }

  handleModalOpen = event => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false })
  }

  render() {
    return (
      <CtaContainer>
        <CtaForm onSubmit={this.handleSubmit}>
          <CtaInput
            placeholder="Enter your email address"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
          />

          <CtaButton type="submit" onClick={this.handleModalOpen}>
            Get early access
          </CtaButton>
          {this.state.validateError === "Valid" && (
            <StyledModal
              isOpen={this.state.isModalOpen}
              onRequestClose={this.handleModalClose}
              contentLabel="Booking Bucket Early Access thank you popup"
            >
              {this.state.submitted && (
                <StyledBackButton onClick={this.handleModalClose}>
                  <BackImage />
                </StyledBackButton>
              )}
              {!this.state.submitted && <LoadingModal />}
              {!this.state.error && this.state.submitted && (
                <SuccessModal email={this.state.email} />
              )}
              {this.state.error && this.state.submitted && <FailureModal />}
            </StyledModal>
          )}
        </CtaForm>
        {this.state.validateError !== "Valid" && (
          <CtaError>{this.state.validateError}</CtaError>
        )}
      </CtaContainer>
    )
  }
}

export default CallToAction

const StyledModal = styled(Modal)`
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 90%;
  }
`

const StyledBackButton = styled.button`
  position: absolute;
  top: 5%;
  margin-left: 2%;
`

const CtaContainer = styled.div`
  height: 100%;
`

const CtaError = styled.div`
  color: red;
  font-weight: ${props => props.theme.fontWeight.bold};
`
