import styled from "styled-components"

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`

export const Section = styled.section`
  padding: 80px 0;
  overflow: hidden;
  background-color: ${props => {
    switch (props.accent) {
      case "secondary":
        return props.theme.color.white.dark
      case "main":
        return props.theme.color.primary
      default:
        return "white"
    }
  }};

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 80px 0;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 40px 0;
  }

  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === "secondary"
        ? props.theme.color.white.dark
        : props.theme.color.primary
    }`};
`

export const SectionContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 32px 0px 32px;
`


export const CtaForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    width: 100%;
  }
`

export const CtaInput = styled.input`
  font-weight: ${props => props.theme.fontWeight.normal};
  font-size: ${props => props.theme.fontSize.regular};
  font-style: italic;
  color: ${props => props.theme.color.secondary.buttonGray};
  line-height: 42px;
  width: 320px;
  text-align: left;
  height: 60px;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary.buttonGray};
  border-image: initial;
  border-radius: 8px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary.buttonGray} 0px 0px
      0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

export const CtaButton = styled.button`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.regular};
  color: ${props => props.theme.color.white};
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${props => props.theme.color.black};
  border-radius: 8px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  filter: drop-shadow(0px 3px 3px #000000cc);
  transition: 0.2s;

  outline: 0px;
  &:hover {
    background-color: ${props => props.theme.color.secondary.yellow};
    color: ${props => props.theme.color.black};
  }
  &:active {
    filter: drop-shadow(0px 0px 1px #00000066);
    transform: translateY(3px);
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

