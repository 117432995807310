import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { SectionContainer, Section } from "../global"

const HowItWorks = () => {
  const query = useStaticQuery(graphql`
    {
      howItWorks: contentfulSection(contentId: { eq: "howItWorks" }) {
        headerText
      }

      howItWorksCards: allContentfulCards(
        filter: { contentId: { eq: "howitworksCard" } }
        sort: { fields: orderNumber }
      ) {
        howItWorksCardsContent: nodes {
          cardImage {
            title
            file {
              url
            }
          }
          cardHeaderFirstLine
          cardBodyFirstLine
          orderNumber
        }
      }
    }
  `)

  const {
    howItWorks,
    howItWorksCards: { howItWorksCardsContent },
  } = query

  return (
    <StyledHowItWorksSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <h3>{howItWorks.headerText}</h3>
        </HeaderTextWrapper>
        <Grid>
          {howItWorksCardsContent.map((data) => (
            <RowItem {...data} key={data.orderNumber}>
              <StyledMediaContainer>
                <StyledImage src={data.cardImage.file.url} alt={data.cardImage.file.title} />
              </StyledMediaContainer>
              <br />
              <CardHeader>{data.cardHeaderFirstLine}</CardHeader>
              <CardText>{data.cardBodyFirstLine}</CardText>
            </RowItem>
          ))}
        </Grid>
      </SectionContainer>
    </StyledHowItWorksSection>
  )
}

export default HowItWorks

const StyledHowItWorksSection = styled(Section)`
  background-color: ${props => props.theme.color.blue80};
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    font-weight: ${props => props.theme.fontWeight.semibold};
    color: ${props => props.theme.color.white};
    line-height: 65px;
    margin-top: 0px;
    margin-bottom: 64px;
    text-align: center;
    @media (max-width: ${props => props.theme.screen.md}) {
      font-weight: ${props => props.theme.fontWeight.semibold};
      ${props => props.theme.fontSize.large};
      line-height: 54px;
    }
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 2rem;
`

const StyledMediaContainer = styled.div`
  width: 100%;
`
const CardHeader = styled.h4`
  color: ${props => props.theme.color.white};
  margin-bottom: 8px;
  margin-top: 32px;
  line-height: 44px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-weight: ${props => props.theme.fontWeight.bold};
    ${props => props.theme.fontSize.regular};
    line-height: 44px;
  }
`

const CardText = styled.p`
  color: ${props => props.theme.color.white};
  margin: 0;
  font-size: 32px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-weight: ${props => props.theme.fontWeight.normal};
    ${props => props.theme.fontSize.regular};
    line-height: 44px;
  }
`

const StyledImage = styled.img`
  height: 100%;
`
