import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"

import { SectionContainer, Section } from "../global"
import "../slider.css"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}


const Solutions = () => {
  const query = useStaticQuery(graphql`
    {
      solutions: contentfulSection(contentId: { eq: "solutions" }) {
        headerText
        paragraphOne {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        paragraphTwo {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }

      solutionCards: allContentfulCards(
        filter: { contentId: { eq: "solutionCard" } }
        sort: { fields: orderNumber }
      ) {
        solutionCardsContent: nodes {
          cardImage{
            title
            file{
              url
            }
          }
          cardHeaderFirstLine
          cardHeaderSecondLine
          cardBodyFirstLine
          orderNumber
        }
      }
    }
  `)

  const { solutions,
    solutionCards: { solutionCardsContent },
  } = query;

  return (
    <StyledSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <HeaderText>{solutions.headerText}</HeaderText>
        </HeaderTextWrapper>
        <TextGroup>
          <p>{solutions.paragraphOne.childMarkdownRemark.rawMarkdownBody} </p>
          <br />
          <p>{solutions.paragraphTwo.childMarkdownRemark.rawMarkdownBody}</p>
        </TextGroup>
        <CardsDivMobile>
          <Slider {...settings}>
            {solutionCardsContent.map(data => (
              <Card {...data} key={data.orderNumber}>
                <StyledMediaContainer>
                  <img
                    src={data.cardImage.file.url}
                    alt={data.cardImage.title}
                  />
                </StyledMediaContainer>
                <br />
                <CardHeader>{data.cardHeaderFirstLine}</CardHeader>
                <CardHeader>{data.cardHeaderSecondLine}</CardHeader>
                <CardText>{data.cardBodyFirstLine}</CardText>
              </Card>
            ))}
          </Slider>
        </CardsDivMobile>
        <CardsDiv>
          <CardsGrid>
            {solutionCardsContent.map(data => (
              <Card {...data} key={data.orderNumber}>
                <StyledMediaContainer>
                  <img
                    src={data.cardImage.file.url}
                    alt={data.cardImage.title}
                  />
                </StyledMediaContainer>
                <br />
                <CardHeader>{data.cardHeaderFirstLine}</CardHeader>
                <CardHeader>{data.cardHeaderSecondLine}</CardHeader>
                <CardText>{data.cardBodyFirstLine}</CardText>
              </Card>
            ))}
          </CardsGrid>
        </CardsDiv>
      </SectionContainer>
    </StyledSection>
  )
}

export default Solutions

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.grey};
`

const HeaderText = styled.h3`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 0px;
  margin-bottom: 64px;
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.black};
    line-height: 65px;
    text-align: center;
    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 40px;
      line-height: 54px;
      font-weight: ${props => props.theme.fontWeight.semibold};
    }
  }
`

const TextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  p {
    margin-top: 0;
    text-align: center;
    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 24px;
      line-height: 33px;
      text-align: left;
    }
  }
`

const CardsGrid = styled.div`
  display: grid;
  padding-top: 64px;
  gap: 32px;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr;
  grid-gap: 64px;
  @media (min-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
  }
`

const Card = styled.div`
  display: grid;
  background: ${props => props.theme.color.white};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 32px;
  height: 100%;
  justify-content: space-between;
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    min-height: 420px;
  }
  @media (max-width: ${props => props.theme.screen.xxs}) {
    min-height: 380px;
  }
`

const StyledMediaContainer = styled.div`
  width: 100px;
  height: 100px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 80px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 60px;
  }
`

const CardsDiv = styled.div`
  @media (max-width: ${props => props.theme.screen.sm_1}) {
    display: none;
  }
`

const CardsDivMobile = styled.div`
  width: 100%;
  height: 100%;
  min-height: 430px;
  @media (min-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`

const CardHeader = styled.h4`
  color: ${props => props.theme.color.black};
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 44px;
  white-space: pre-line;
  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 32px;
    white-space: pre-line;
  }
  @media (max-width: ${props => props.theme.screen.xxs}) {
    font-size: 24px;
    line-height: 32px;
    white-space: normal;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    white-space: normal;
  }
`

const CardText = styled.p`
  color: ${props => props.theme.color.black};
  margin: 0;
  margin-top: 8px;
  font-size: 22px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 24px;
    line-height: 32px;
  }
  @media (max-width: ${props => props.theme.screen.xxs}) {
    font-size: 18px;
  }
`
