import { graphql, useStaticQuery } from "gatsby"

const useCtaOnlyData = () => {
  const data = useStaticQuery(graphql`
    {
      ctaonly: contentfulSection(contentId: { eq: "cta_only" }) {
        headerText
      }
    }
  `)

  const ctaOnly = data

  return ctaOnly
}

export default useCtaOnlyData
