import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { SectionContainer, Section } from "../global"

const Advantage = () => {
  const query = useStaticQuery(graphql`
    {
      advantageCards: allContentfulCards(
        filter: { contentId: { eq: "advantageCard" } }
        sort: { fields: orderNumber }
      ) {
        advantageCardsContent: nodes {
          cardImage {
            title
            file {
              url
            }
          }
          cardHeaderFirstLine
          cardBodyFirstLine
          orderNumber
        }
      }
    }
  `)

  const {
    advantageCards: { advantageCardsContent },
  } = query

  return (
    <StyledAdvantageSection>
      <SectionContainer>
        <Grid>
          {advantageCardsContent.map(data => (
            <RowItem key={data.orderNumber}>
              <StyledMediaContainer>
                <img src={data.cardImage.file.url} alt={data.cardImage.title} />
              </StyledMediaContainer>
              <br />
              <CardHeader>{data.cardHeaderFirstLine}</CardHeader>
              <CardText>{data.cardBodyFirstLine}</CardText>
            </RowItem>
          ))}
        </Grid>
      </SectionContainer>
    </StyledAdvantageSection>
  )
}

export default Advantage

const StyledAdvantageSection = styled(Section)`
  background-color: ${props => props.theme.color.background.white};
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 2rem;
`

const StyledMediaContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  max-height: 200px;
`
const CardHeader = styled.h4`
  color: ${props => props.theme.color.black};
  margin-bottom: 16px;
  margin-top: 0px;
  line-height: 44px;
`

const CardText = styled.p`
  color: ${props => props.theme.color.black};
  margin: 0;
  font-size: 22px;
  line-height: 33px;
`
