import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { SectionContainer, Section } from "../global"

const Team = () => {
  const query = useStaticQuery(graphql`
    {
      teamCards: allContentfulCards(
        filter: { contentId: { eq: "teamCard" } }
        sort: { fields: orderNumber }
      ) {
        teamCardsContent: nodes {
          cardImage {
            title
            file {
              url
            }
          }
          cardHeaderFirstLine
          cardBodyFirstLine
          orderNumber
        }
      }
    }
  `)

  const {
    teamCards: { teamCardsContent },
  } = query

  return (
    <StyledTeamSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <HeaderText>Meet the team</HeaderText>
          <p>
            We are a group of business owners and developers who want to help you
            improve your day to day and grow your business.
          </p>
        </HeaderTextWrapper>
        <Grid>
          {teamCardsContent.map((data) => (
            <RowItem key={data.orderNumber}>
              <StyledMediaContainer>
                <StyledRowItemImage src={data.cardImage.file.url} alt={data.cardImage.title} />
                {/* <RowItemImage height={100}/> */}
              </StyledMediaContainer>
              <br />
              <CardHeader>{data.cardHeaderFirstLine}</CardHeader>
              <CardText>{data.cardBodyFirstLine}</CardText>
            </RowItem>
          ))}
        </Grid>
      </SectionContainer>
    </StyledTeamSection>
  )
}

export default Team

const StyledTeamSection = styled(Section)`
  background-color: ${props => props.theme.color.black};
`

const HeaderTextWrapper = styled.div`
  margin: 0;
  text-align: center;
  max-width: 900px;
  margin-bottom: 32px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h4 {
    font-weight: ${props => props.theme.fontWeight.semibold};
    color: ${props => props.theme.color.white};
    margin-top: 0;
    margin-bottom: 32px;
  }

  p {
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.color.white};
    line-height: 33px;
    margin-bottom: 0;
    margin-top: 0;
  }
`

const HeaderText = styled.h4`
  ${props => props.theme.fontSize.larger};
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 2rem;
`

const StyledMediaContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  max-height: 200px;
`

const StyledRowItemImage = styled.img`
  height: 100%;
`

const CardHeader = styled.h4`
  color: ${props => props.theme.color.white};
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 44px;
  text-align: center;
`

const CardText = styled.p`
  color: ${props => props.theme.color.white};
  margin: 0;
  line-height: 33px;
  text-align: center;
`
