import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { SectionContainer, Section } from "../global"

const Empathy = () => {
  const query = useStaticQuery(graphql`
    {
      empathy: contentfulSection(contentId: { eq: "empathy" }) {
        headerText
        boldedText
      }
    }
  `)

  return (
    <StyledSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <h3>
            {query.empathy.headerText} <br />
            {query.empathy.boldedText}
          </h3>
        </HeaderTextWrapper>
      </SectionContainer>
    </StyledSection>
  )
}

export default Empathy

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.blue80};
`

const HeaderTextWrapper = styled.div`
  margin: 0;
  text-align: center;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.light};
    color: ${props => props.theme.color.white};
    line-height: 54px;
    font-style: italic;
  }
`
