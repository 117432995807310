import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { SectionContainer, Section } from "../global"

const Stakes = () => {
  const query = useStaticQuery(graphql`
    {
      stakes: contentfulSection(contentId: { eq: "stakes" }) {
        headerText
        boldedText
        paragraphOne {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        paragraphTwo {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        sectionImage {
          title
          description
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <HeaderText>{query.stakes.headerText}</HeaderText>
        </HeaderTextWrapper>
        <Flex>
          <TextGroup>
            <BoldedText>{query.stakes.boldedText}</BoldedText>
            <TextGroupParagraph>
              {" "}
              {query.stakes.paragraphOne.childMarkdownRemark.rawMarkdownBody}{" "}
            </TextGroupParagraph>
            <br />
            <br />
            <TextGroupParagraph>
              {query.stakes.paragraphTwo.childMarkdownRemark.rawMarkdownBody}{" "}
            </TextGroupParagraph>
          </TextGroup>
          <ImageWrapper>
            <StyledImage
              src={query.stakes.sectionImage.file.url}
              alt={query.stakes.sectionImage.file.title}
            />
            <br />
          </ImageWrapper>
        </Flex>
      </SectionContainer>
    </StyledSection>
  )
}

export default Stakes

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.grey};
`

const Flex = styled.div`
  display: grid;
  padding-top: 64px;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr auto;
  gap: 100px;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    gap: 0;
  }
`

const HeaderText = styled.h3`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 0px;
  text-align: center;
  margin-bottom: 0px;
`

const BoldedText = styled.p`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 0px;
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.black.regular};
    line-height: 65px;
    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 40px;
      line-height: 54px;
      font-weight: ${props => props.theme.fontWeight.semibold};
    }
  }
`

const TextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }
`

const TextGroupParagraph = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 24px;
    line-height: 33px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.img`
  width: 100%;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    display: none;
  }
`
