import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

export const DesktopPlayer = (data) => {

  function sendAnalyticsEvent(video_state) {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", video_state, {
        send_to: process.env.GOOGLE_MEASUREMENT_ID,
      })
    }
  }

    return (
      <ReactPlayer
        url={`https://youtu.be/${data.urlId}`}
        width="100%"
        height="100%"
        controls={true}
        pip={true}
        wrapper={PlayerWrapper}
        stopOnUnmount={false}
        onStart={() => sendAnalyticsEvent("video_started")}
        onEnded={() => sendAnalyticsEvent("video_ended")}
      />
    )
}

const PlayerWrapper = styled.div`
  height: 640px !important;
  @media (max-width: ${props => props.theme.screen.lg}) {
    height: 505px !important;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    height: 370px !important;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 265px !important;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    height: 200px !important;
  }
  @media (max-width: ${props => props.theme.screen.xxs}) {
    height: 175px !important;
  }
  margin-bottom: 64px;
`