import React from "react"
import styled from "styled-components"
import { DesktopPlayer } from "../common/video_player/video"
import CtaFormMail from "../common/call_to_action/cta"
import useDemoData from "../common/queries/demo"

import { SectionContainer, Section } from "../global"

const Demo = () => {
  const {
    demo: { headerText, urlId },
  } = useDemoData()

  return (
    <StyledSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <h3>{headerText}</h3>
        </HeaderTextWrapper>
        <DesktopPlayer urlId={urlId} />
        <CtaFormMail location="demo_video"/>
      </SectionContainer>
    </StyledSection>
  )
}

export default Demo

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.grey};
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    font-weight: ${props => props.theme.fontWeight.semibold};
    line-height: 65px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 64px;
  }
`
