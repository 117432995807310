import { graphql, useStaticQuery } from "gatsby"

const useDemoData = () => {
  const data = useStaticQuery(graphql`
    {
      demo: contentfulSection(contentId: { eq: "demo" }) {
        headerText
        urlId
      }
    }
  `)

  const demo = data

  return demo
}

export default useDemoData
