import React from "react"
import styled from "styled-components"
import { SectionContainer, Section } from "../../global"
import usePopupData from "../queries/popup"

const FailurePopup = () => {

  const {
    failure: {
      headerText,
      popupImage: {
        title,
        file: {
          url
        }
      },
      emailBody: {
        childMarkdownRemark: {
        html : textWithEmailBody
      }
      }
    }
  } = usePopupData();

  return (
    <StyledFailurePopupSection>
      <SectionContainer>
        <HeaderTextWrapper>
          <HeaderText>{headerText}</HeaderText>
        </HeaderTextWrapper>
        <StyledImage src={url} alt={title} />
        <TextGroup>
          <p dangerouslySetInnerHTML={{ __html: textWithEmailBody }}></p>
        </TextGroup>
      </SectionContainer>
    </StyledFailurePopupSection>
  )
}

export default FailurePopup

const StyledFailurePopupSection = styled(Section)`
  background-color: ${props => props.theme.color.background.white};
  border: solid 3px ${props => props.theme.color.black};
  border-radius: 20px;
`

const StyledImage = styled.img`
  margin-bottom: 32px;
  width: 25%;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
    margin-bottom: 0px;
  }
`
const HeaderText = styled.h3`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-top: 0px;
  margin-bottom: 64px;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 20px;
  }
`

const HeaderTextWrapper = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h3 {
    line-height: 65px;
    @media (max-width: ${props => props.theme.screen.md}) {
      line-height: 45px;
    }
  }
`

const TextGroup = styled.div`
  margin: 0;
  margin-bottom: 32px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    @media (max-width: ${props => props.theme.screen.md}) {
      font-size: 18px;
      line-height: 32px;
    }
  }
`