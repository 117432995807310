import { graphql, useStaticQuery } from "gatsby"

const useSocialIconData = () => {
  const data = useStaticQuery(graphql`
    {
      icons: allContentfulSocialIcons {
        iconsContent: nodes {
          name
          url
          position
        }
      }
    }
  `)

  const { icons } = data

  return { icons }
}

export default useSocialIconData
